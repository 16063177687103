
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import Auth from "@/mixins/AuthMixin";
import { ValidationObserver } from "vee-validate";
import EBtn from "@/components/common/EBtn.vue";

type UserDocType = "RUT";

interface UserPropertyData {
  doc_id?: string;
  doc_type?: UserDocType;
  company_name?: string;
}

interface UserRegisterOptions {
  name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  property?: UserPropertyData;
}

@Component({ name: "register-form", components: { EBtn } })
export default class RegisterForm extends Mixins(Auth) {
  userData!: UserRegisterOptions;
  bLoading = false;
  bAccept = false;

  @Ref("observer") readonly obObserver!: InstanceType<
    typeof ValidationObserver
  >;

  get hasSlot(): boolean {
    return !this.$_.isEmpty(this.$slots);
  }

  created(): void {
    this.userData = {
      name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      property: {
        doc_id: "",
        doc_type: "RUT",
        company_name: "",
      },
    };
  }

  async onRegister(): Promise<void> {
    const valid = await this.obObserver.validate();
    if (!valid || this.bLoading) {
      return;
    }

    this.bLoading = true;

    try {
      const response = await this.register(this.userData);
      if (response && response.user && !this.preventRedirect) {
        this.$router.push({ name: "home" });
      }
    } catch (error) {
      this.$toast.error(error.message);
    }

    this.bLoading = false;
  }
}
